import React, { HTMLProps } from 'react';
import { cva } from 'class-variance-authority';

import { SectionContainer } from '../../components/common/sectionContainer';
import { cn } from '../../utils/common';
import { ProCard } from '../common/ProCard';
import { ArrowRight, ArrowRightShort } from '@styled-icons/bootstrap';
import { Link } from 'gatsby';
import { APICard } from '../common/UI/APICard';

const rootCls = cva(
   cn('py-[5rem]'),
   {
      variants: {
         bg: {
            gray: 'bg-[var(--ifm-color-secondary-lighter)]',
            dark: ''
         }
      }
   }
)

const innerCls = cva(
   cn('layout'),
   {
      variants: {
      }
   }
)

const cardCls = cva(
   cn('card min-h-[270px] max-h-[270px] hover:bg-primary-100/2 group min-w-[250px] max-w-[250px] flex flex-col cursor-pointer shadow-none border border-solid border-[transparent] hover:border-primary-100 gap-2.5'),
   {
      variants: {
      }
   }
)

const imgClassName = cva(
   cn('w-16 h-16 flex-shrink-0 m-auto')
)

const linkCls = cva(
   cn(
      'group-hover:bg-primary-100/10 w-fit py-3 px-3 rounded-sm ml-auto mr-auto cursor-pointer'
   )
)

type WhatIsUnizoProps = {
   bg?: 'gray' | 'dark'

   rootClassName?: string
}

const conveyPointes = [
   {
      name: 'Accelerate time to market',
      description: 'One API for entire categories of integrations, launch multiple integrations in days, not months. Accelerate revenue growth effortlessly.',
      image: {
         src: '/icon-set/time-to-market.svg',
         alt: 'integrations'
      }
   },
   {
      name: 'Lower costs of integrations',
      description: 'Deploy integrations rapidly with junior developer, avoiding a perpetual recurring costs of dedicated engineering teams per integration. Allocate savings to advance core features on your roadmap.',
      image: {
         src: '/icon-set/lower-costs.svg',
         alt: 'integrate'
      }
   },
   {
      name: 'Improve customer experience',
      description: 'Maintain continuous visibility and monitoring of integration health. Receive proactive alerts and diagnostics for swift integration issue remediation.',
      image: {
         src: '/icon-set/user-experience.svg',
         alt: 'support'
      }
   }
];

const integrationTypes = [
   {
      name: 'Source Code',
      description: 'Integrate with industry leading SCM providers.',
      image: {
         src: '/icon-set/code.svg',
         alt: 'source code'
      },
      to: '/integrations/scm'
   },
   {
      name: 'Container Registry',
      description: 'Out of the box package management.',
      image: {
         src: '/icon-set/container.svg',
         alt: 'container registry'
      },
      to: '/integrations/pcr'
   },
   {
      name: 'Ticketing',
      description: 'Ready made Ticketing integration and ITSM providers.',
      image: {
         src: '/icon-set/unz_Ticketing.svg',
         alt: 'ticketing'
      },
      to: '/integrations/ticketing'
   },
   {
      name: 'Communications',
      description: 'Leverage pre-built chat tools integrations.',
      image: {
         src: '/icon-set/comms.svg',
         alt: 'communication'
      },
      to: '/integrations/communications'
   }
]

export const WhatIsUnizo = React.forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement> & WhatIsUnizoProps>((props, ref) => {

   const {
      className,
      rootClassName,
      bg = 'gray',
      ...rest
   } = props;

   return (
      <div
         className={rootCls({
            className: rootClassName,
            bg
         })}
         {...rest}
         ref={ref}
      >
         <div className={innerCls({ className })}>
            <SectionContainer
               title={'Why to choose Unizo'}
               rootClassName='!py-0'
               noAnim
            />

            <div data-aos='fade-up' className='grid my-10 grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
               {conveyPointes.map((i, key) => {
                  return (
                     <ProCard
                        noAnim
                        key={key}
                        transparent
                        align='start'
                        pointsProps={{ gap: 'lg' }}
                        image={{
                           ...i.image,
                           alt: key?.toString()
                        }}
                        titleSize='lg'
                        title={i.name}
                        description={i.description}
                     />
                  )
               })}
            </div>

            <div className='flex-wrap my-10 flex flex-row justify-center gap-10'>
               {integrationTypes.map((i, key) => (
                  <APICard
                     {...i} key={key}
                     extra={
                        <button className={linkCls({ className: '' })}>
                           <ArrowRightShort className='h-7 w-7 font-[700] text-primary-200' />
                        </button>
                     }
                  />
               ))
               }
            </div>
         </div>
      </div>
   )
})