import { cva } from 'class-variance-authority';
import React, { HTMLProps } from 'react';

import { cn } from '../../../utils/common';
import { ProSectionContainer } from '../ProSectionContainer.tsx';


type SecurityBannerProps = {
   title?: string
   subTitle?: string
   description?: string
   image?: HTMLProps<HTMLImageElement>

   rootClassName?: string
   theme?: 'dark'
   noAnim?: boolean
}

const rootCls = cva(
   cn('px-20 py-14 rounded-3xl my-20 flex flex-col lg:flex-row justify-between items-center'),
   {
      variants: {
         theme: {
            'dark': 'primary-gradient'
         }
      }
   }
)


 export const SecurityBanner = React.forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement> & SecurityBannerProps>(
    (props, ref) => {

       const {
           title = 'Security You Can Count On',
           subTitle = (
              `We're SOC2 Type II certified.`
           ),
           description = (
              'At Unizo , we ensure that our platform meets the highest security and compliance standards, giving you peace of mind while you focus on creating. Our dedication to robust global infrastructure guarantees the safety and integrity of your data.'
           ),

          image,
          rootClassName,
          noAnim = true,
          theme = 'dark'
       } = props;

       return (
          <div className='layout'>
              {/* <div
                ref={ref}
                className={rootCls({
                   className: rootClassName,
                   theme
                })}
             >  */}
                <div className='flex flex-col items-start'>
                   {/* <ProSectionContainer
                      title={title}
                      titleProps={{
                         as: 'h5',
                         strong: 'medium'
                      }}
                      noAnim={noAnim}
                      descriptionProps={{
                         as: 'sm',
                         strong: 'light'
                      } as any}
                      className='rounded-xl !p-0'
                      dark={theme === 'dark'}
                      description={description}
                      align='start'
                   /> */}
                   {/* <span className='ml-2 mt-2 text-white text-sm font-medium' >{subTitle}</span> */}
                </div>
                <div
                   className="z-10 mt-5 flex flex-col items-center gap-5 md:flex-row lg:mt-0"
                >
                   {/* <img alt="SOC2"
                      loading="lazy"
                      width="200"
                      height="200"
                      decoding="async"
                      src="/images/certification.svg"
                      {...image}
                   /> */}
                </div>
             </div>
         //  </div>
       )
    })