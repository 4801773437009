import { cva } from 'class-variance-authority';
import React, { HTMLProps } from 'react';
import Marquee from "react-fast-marquee";
import { cn } from '../../../utils/common';

const rootCls = cva(
   cn('layout scroller')
)

export const ScrollContainer = React.forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement>>((props, ref) => {

   const { className, children, ...rest } = props;

   return (
      <div
         className={rootCls({ className })}
         ref={ref}
         {...rest}
         data-animated={true}
      >
         {/* <div className="scroll mask">
            <div className="m-scroll">
               {children}
            </div>
         </div> */}

         <Marquee speed={30} className='gap-10' delay={0} pauseOnHover>
            {children}
         </Marquee>

      </div>
   )
})