import React, { HTMLProps } from 'react';
import { cva } from 'class-variance-authority';
import { Link } from 'gatsby';

import { cn } from '../../../utils/common';
import { ArrowRightShort } from '@styled-icons/bootstrap';


const cardCls = cva(
   cn('card min-h-[270px] max-h-[270px] hover:bg-primary-100/2 group min-w-[250px] max-w-[250px] flex flex-col cursor-pointer shadow-none border border-solid border-[transparent] hover:border-primary-100 gap-2.5'),
   {
      variants: {
      }
   }
)

const imgClassName = cva(
   cn('w-16 h-16 flex-shrink-0 m-auto')
)

const linkCls = cva(
   cn(
      'group-hover:bg-primary-100/10 w-fit py-3 px-3 rounded-sm ml-auto mr-auto cursor-pointer'
   )
)

type APICardProps = {
   name?: string
   image?: HTMLProps<HTMLImageElement>
   to?: string
   description?: string
   extra?: React.ReactElement
}

export const APICard = React.forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement> & APICardProps>((props, ref: any) => {

   const { name, description, image, to = '/', extra = null, ...rest }: any = props;

   return (
      <Link to={to} className={cardCls()} {...rest} ref={ref}>
         {image?.src && (
            <img className={imgClassName()} src={image.src} alt={image?.alt} />
         )}
         <span className='text-md font-semibold leading-5 text-gray-900 text-center' >{name}</span>
         <span className='text-md font-medium leading-7 text-center text-gray-800 ellipse-text' >{description}</span>
         {extra && extra}
      </Link>
   )
})

const items = [
   {
      name: 'qwdqwd',
   },
   {
      name: 'qwdqwd',
   },
   {
      name: 'qwdqwd',
   }
]
