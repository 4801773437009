import React from 'react';
import '../../styles/pricing.css';
import { HeroBanner } from '../../components/homepage/herobanner';
import { Section2 } from '../../components/homepage/section2';
import ResourcesSection from '../../components/homepage/ResourcesSection';
import HelpSection from '../../components/homepage/HelpSection';
import CommunitySection from '../../components/homepage/CommunitySection';
import Layout from '../../components/common/Layout';
import WhyUnizo from '../../components/homepage/whyUnizo';
import { IntegrationPoints } from '../../components/homepage/IntegrationPoints';
import { WhatIsUnizo } from '../../components/homepage/whatisUnizo';
import { docsLink } from '../../utils/common';
import { EverydayUseCases } from '../../components/homepage/everydayUsecases';
import { SecurityBanner } from '../../components/common/SecurityBanner';
import { ScrollContainer } from '../../components/common/UI/ScrollAnimContainer';
import { ProSectionContainer } from '../../components/common/ProSectionContainer';

const nodes = [{
   title: 'SaaS ',
   description: 'Streamlined integration with Unizo cloud-based service, ideal for most customers seeking simplicity and scalability.',
   extra: [
      { name: 'Learn More →', to:'https://unizo.io/platform/security-and-reliability' }
   ],
   image: {
      src: '/icon-set/cloud.svg',
      alt: 'SaaS'
   }
},
{
   title: 'Self-Managed ',
   description: 'Control and compliance in your hands. Deploy and manage integrations on your own infrastructure for enhanced security.',
   extra: [
      { name: 'Learn More →', to:'https://unizo.io/platform/security-and-reliability' }
   ],
   image: {
      src: '/icon-set/deployment-model.svg'
   }
},
{
   title: 'Integration Expertise',
   description: 'Unizo simplifies the integration process, freeing you up to focus on core product development and innovation.',
   extra: [
      { name: 'Learn More →', to: 'https://unizo.io/platform/security-and-reliability' }
   ],
   image: {
      src: '/icon-set/integrations.svg'
   }
}
]

const providers = [
   {
      src: '/images/github.svg'
   },
   {
      src: '/images/gitlab.svg'
   },
   {
      src: '/images/aws.svg'
   },
   {
      src: '/images/bitbucket.svg'
   },
   {
      src: '/images/jfrog.svg'
   },
   {
      src: '/images/docker.svg'
   },
   {
      src: '/images/ado.svg'
   },
   {
      src: '/images/trello.svg'
   },
   {
      src: '/images/jira.svg'
   },
   {
      src: '/images/teams.svg'
   },
   {
      src: '/images/salesforce.svg'
   },
   {
      src: '/images/slack.svg'
   },
   {
      src: '/images/googlechat.svg'
   },
   {
      src: '/images/service.svg'
   },
   {
      src: '/images/opsgenie.svg'
   },
   {
      src: '/images/nexus.svg'
   }
]

const Home = () => {
   return (
      <Layout disablePageTitleSuffix title='Unizo - Integrations for Cybersecurity, Devops'>
         <HeroBanner />
         {/* <Carousel title='Integrated Providers' /> */}
         <ProSectionContainer
            nodeType='html'
            title={<p>Enabling Integrations with <span className='primary-text-gradient'>Leading Market Providers</span></p>}
            titleProps={{ as: 'h4' }}
            extraNodes={
               <ScrollContainer>
                     {providers.map(({ src }, index) => {
                        return (
                           <span key={index} className='ml-10'>
                              <img
                                 className='aspect-auto w-auto max-h-[52px]'
                                 src={src}
                              />
                           </span>
                        )
                     })}
               </ScrollContainer>
            }
         />
         <WhatIsUnizo />
         <EverydayUseCases />
         {/* <WhyUnizo /> */}
         <IntegrationPoints
            nodes={nodes}
            rootClassName='!my-0'
            title='Enterprise-Ready Platform'
            description={'Launch, grow and scale your products efficiently'}
         />
         <SecurityBanner />
         <div className="z-0 sticky">
            <HelpSection />
         </div>
         <CommunitySection className='pt-64' />
      </Layout>
   )
};

export default Home;
