import { cva } from 'class-variance-authority';
import React, { HTMLProps } from 'react';
import { cn } from '../../utils/common';
import { SectionContainer } from '../common/sectionContainer';
import { Link } from 'gatsby';
import { WithFadeUp } from '../../hoc/withFadeup';


 const rootCls = cva(
    cn('grid grid-cols-1 lg:grid-cols-2 gap-5 mt-10 layout')
 )

 export const EverydayUseCases = React.forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement>>((props, ref) => {

    const { className } = props;

     return (
       <div className={rootCls({ className })} {...props} ref={ref}>
           {/* <SectionContainer
              rootClassName='!p-0'
              align='start'
              title={'Integrations for all your Security and DevOps use-cases'}
              description={
                 `Explore our tailored integration workflows covering static analysis, API security, SCA, application security posture, cloud security, SOAR, SIEM, and more. Elevate your defenses with Unizo customizable solutions designed to meet every aspect of your cybersecurity strategy.
                 `
              }
              extras={[
                 <Link className='btn btn-primary btn-lg' to='https://unizo.io/use-cases/sourcecode-staticanalysis'>Explore UseCases →</Link>
              ]}
              noAnim
           />
           <img alt='everyday_usecases' className='max-w-[300px] lg:max-w-[600px] mr-auto ml-auto lg:mr-0 lg:ml-auto' src='/images/everyday_usecases_2.png' /> */}
        </div>
     )
 })